<template>
  <div class="page">
    <!-- 头部 -->
    <!-- <nav_top /> -->
    <div class="zhanwei">
      <!-- 占位 -->
    </div>
    <div class="address_top" v-if="order_info_data.is_address != 0">
      <div class="title">地址管理:</div>
      <ul>
        <li
          :class="current_address == item.id ? 'active' : ''"
          v-for="(item, index) in address_List"
          :key="index"
          @click="chang_address(index, item)"
        >
          <div class="top">
            <div class="name">{{ item.consignee }}</div>
            <div class="is_defult" v-if="item.is_default == 1">默认地址</div>
          </div>
          <div class="bottom">
            <div class="phone">{{ item.mobile }}</div>
            <div class="text">{{ item.region }}{{ item.street }}</div>
          </div>
        </li>
        <li class="add_btn" @click="add_address">
          <i
            class="el-icon-plus add_"
            style="color: #b6b6b6; font-size: 34px"
          ></i
          >添加地址
        </li>
      </ul>
    </div>
    <div class="goods_info">
      <div class="name_wrap">商品信息：</div>
      <div
        class="dec_wrap"
        v-for="(item, index) in order_info_data.good"
        :key="index"
      >
        <div v-if="item.good_type == 3" class="name">{{item.good_info}} —— {{ item.good_name }}</div>
        <div v-else class="name">{{ item.good_name }}</div>
        <div class="num_">
          <span class="num_1">X{{ item.buy_num }}</span>
          <span class="price"
            >￥{{ item.is_free == 1 ? item.good_present_price : "免费" }}</span
          >
        </div>
      </div>
    </div>
    <div class="coupon" @click="handel_user_coupon">
      <div class="title">
        优惠劵
        <span
          v-if="
            order_info_data &&
            order_info_data.user_coupon &&
            order_info_data.user_coupon.length == 0
          "
          >（暂无可用）</span
        >
      </div>
      <i class="el-icon-arrow-right" style="color: #b6b6b6"></i>
    </div>
    <div
      class="coupon"
      style="margin-top: 20px"
      v-if="order_info_data.is_address != 0"
    >
      <div class="title">
        运费
        <span>￥{{ order_info_data.dispatch_price }}</span>
      </div>
      <!-- <i class="el-icon-arrow-right" style="color: #b6b6b6"></i> -->
    </div>
    <div class="pay_info">
      <div class="title_">支付信息：</div>
      <div class="bottom">
        <div class="num_info">
          <div class="total_price">商品金额：￥{{ original_price }}</div>
          <div class="total_price">优惠金额：-￥{{ discount_price }}</div>
          <div class="total_p">
            合计：
            <p>
              ￥
              <span>{{ result_price }}</span>
            </p>
          </div>
        </div>
        <div class="btn_wrap" @click="go_pay">提交订单</div>
      </div>
    </div>

    <!-- 底部 -->
  </div>
</template>

<script>
import { mapActions } from "vuex";
import api from "@/config/api";
let Base64 = require("js-base64").Base64;
export default {
  name: "course_info",
  data() {
    return {
      address_List: [],
      current_address: "",
      course_id: "",
      order_info_data: {},
      result_price: 0,
      discount_list: [],
      discount_price: 0,
      original_price: 0,
    };
  },
  created() {},
  mounted() {
    var base64_checked_str = JSON.parse(Base64.decode(this.$route.params.good));
    this.param = base64_checked_str;
    // 获取当前的路径
    this.re_url = this.$route.fullPath;
    this.getData();
  },

  methods: {
    ...mapActions(["setUserAndState"]),
    add_address() {
      //  this.$router.push(`/mine/my_address?item=5?re_url=${this.re_url}`);
      let { href } = this.$router.resolve(
        `/mine/my_address?item=5&re_url=${this.re_url}`
      );
      window.open(href, "_blank");
    },
    async go_pay() {
      let islogin = await this.islogin();
      if (islogin) {
        var address_id = 0;
        if (this.order_info_data.is_address != 0) {
          address_id = this.current_address;
        }
        var coupon_id = 0;
        if (this.order_info_data.order_is_coupon != 0) {
          coupon_id = "";
        }

        try {
          let res = await api.enter({
            good_id: JSON.stringify(this.param),
            address_id: address_id,
            coupon_id: coupon_id,
            // remark: this.remark
            source: 2,
          });
          if (res.code == 0) {
            var param = Base64.encode(
              JSON.stringify({
                oid: res.result.oid,
                price: res.result.order_price,
              })
            );

            // 一个免费商品  直接跳转支付成功页
            if (res.result.order_price == 0) {
              this.$router.push(`/pay_success/${res.result.oid}`);
              return;
            }
            this.$router.push(`/pay/${param}`);
          } else {
            this.$message(res.message);
          }
        } catch (e) {
          console.log(e);
        }
      } else {
        this.$router.push(`/login?re_url=${this.$route.path}`);
      }
      // let { href } = this.$router.resolve(
      //   `/mine/my_address?item=5&re_url=${this.re_url}`
      // );
      // window.open(href, "_blank");
    },
    chang_address(e, item) {
      console.log(e, item);
      this.current_address = item.id;
      // this.address_List.forEach((v, i) => {
      //   e == i ? (v.is_default = 1) : (v.is_default = 0);
      // });
      // this.consignee = item.consignee;
      // this.address_id = item.id;
      // this.is_default = item.is_default;
      // this.mobile = item.mobile;
      // this.street = item.street;
      // console.log(is_default);
      // var prams = {
      //   consignee: this.consignee,
      //   id: this.address_id,
      //   is_default: this.is_default,
      //   mobile: this.mobile,
      //   street: this.street,
      // };
      // setTimeout(() => {
      //   this.edit_address(prams);
      // }, 1000);
    },
    handel_user_coupon() {
      // console.log(11);
      if (this.order_info_data.user_coupon.length == 0) {
        this.$notify({
          title: "提示信息",
          message: "暂无优惠券",
          type: "info",
        });
      }
    },
    // async edit_address(prams) {
    //   try {
    //     const res = await api.edit_address(prams);
    //     if (res.code == 0) {
    //       console.log(res.result);
    //       this.$notify({
    //         title: "提示信息",
    //         message: "修改成功",
    //         type: "success",
    //       });
    //       // this.getData();
    //     }
    //   } catch (error) {
    //     console.log(error);
    //     this.$notify({
    //       title: "提示信息",
    //       message: "修改失败",
    //       type: "error",
    //     });
    //   }
    // },
    async get_addressList() {
      try {
        const res = await api.address_List();
        if (res.code == 0) {
          this.address_List = res.result;

          let current_address = res.result.filter((item, index) => {
            return item.is_default == 1;
          });
          if (current_address && current_address.length) {
            this.current_address = current_address[0].id;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getData() {
      let _this = this;
      // console.log(JSON.stringify(this.param),".......");
      try {
        const res = await api.order_detail({
          good_id: JSON.stringify(this.param),
        });
        if (res.code == 0) {
          let data = res.result;
          if (data.is_address) {
            this.get_addressList();
          }
          this.order_info_data = data;
          let {
            order_original_price, // 商品原价（不加运费）
            order_present_price, // 商品现价（不加运费）
            dispatch_price, // 运费
            user_coupon, // 优惠券
          } = res.result;
          this.discount_list = user_coupon;
          this.original_price = order_original_price + dispatch_price; //订单原价
          this.result_price = order_present_price + dispatch_price; // 最终价格
          this.freight_price = dispatch_price; // 运费
          this.discount_price = this.original_price - this.result_price; // 优惠价格
        } else {
          this.$message({
            showClose: true,
            message: res.message,
            type: "warning",
          });
          setTimeout(function () {
            _this.$router.push(`/`);
          }, 1000);
        }
      } catch (error) {
        console.log(error);
      }
    },
    islogin() {
      this.setUserAndState();
      const { logined } = this.$store.state;
      if (logined) {
        return true;
      } else {
        return false;
      }
    },
  },
  components: {},
};
</script>

<style lang="less" scoped>
.page {
  background-color: #f5f6f8;
  min-height: calc(100vh - 451px);
  padding-bottom: 100px;
}
.zhanwei {
  height: 22px;
}
.address_top {
  text-align: left;
  margin: 0 auto;
  padding: 30px 40px;
  background: #ffffff;
  border-radius: 16px;
  width: 1200px;
  //   height: 298px;
  background: #ffffff;
  border-radius: 16px;
  box-sizing: border-box;
  .title {
    margin-bottom: 34px;
    font-size: 20px;
    font-weight: 600;
    color: #333333;
  }
  ul {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    li.active::after {
      content: "";
      //   background: url(../assets/login/is_defult.png) no-repeat cover;
      background: url(../assets/login/is_defult.png);
      background-size: contain;
      background-repeat: no-repeat;
      width: 38px;
      height: 38px;
      position: absolute;
      bottom: -2px;
      right: -2px;
    }
    li.active {
      border: 2px dashed #479dff;
      position: relative;
    }
    .add_btn {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 400;
      color: #999999;
      line-height: 28px;
      .add_ {
        margin-right: 10px;
      }
    }
    li {
      width: 288px;
      height: 179px;
      border: 2px dashed #b6b6b6;
      border-radius: 8px;
      padding: 22px 25px;
      margin-right: 21px;
      box-sizing: border-box;
      cursor: pointer;
      margin-bottom: 20px;
      transition: all 0.4s;

      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 11px;
        border-bottom: 1px solid #e9e9e9;
        box-sizing: border-box;
        .name {
          font-size: 16px;
          font-weight: 500;
          color: #333333;
        }
        .is_defult {
          width: 76px;
          height: 26px;
          background: #00c87f;
          border-radius: 13px;
          line-height: 26px;
          text-align: center;
          font-size: 14px;
          font-weight: 500;
          color: #ffffff;
        }
      }
      .bottom {
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        .phone {
          margin: 14px 0;
        }
      }
    }
  }
}
.goods_info {
  padding: 30px 40px;
  width: 1200px;
  background: #ffffff;
  border-radius: 16px;
  box-sizing: border-box;
  margin: 20px auto;
  text-align: left;
  .name_wrap {
    font-size: 20px;
    font-weight: 600;
    color: #333333;
    line-height: 38px;
  }
  .dec_wrap {
    margin-top: 20px;
    width: 1120px;
    // height: 82px;
    background: #fafcfe;
    padding: 15px 30px;
    box-sizing: border-box;
    .name {
      font-size: 20px;
      font-weight: 600;
      color: #333333;
    }
    .num_ {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      font-weight: 500;
      color: #999999;
    }
    .price {
      font-size: 22px;
      font-weight: 600;
      color: #333333;
    }
  }
}
.coupon {
  text-align: left;
  margin: 0 auto;
  width: 1200px;
  padding: 40px;
  box-sizing: border-box;
  //   height: 96px;
  background: #ffffff;
  border-radius: 16px;
  .title {
    font-size: 20px;
    font-weight: 600;
    color: #333333;
    line-height: 38px;
    span {
      font-size: 20px;
      font-weight: 500;
      color: #999999;
      line-height: 38px;
    }
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pay_info {
  text-align: left;
  width: 1200px;
  height: 188px;
  background: #ffffff;
  border-radius: 16px;
  margin: 0 auto;
  margin-top: 22px;
  padding: 30px 40px;
  box-sizing: border-box;
  margin-bottom: 42px;

  .title_ {
    font-size: 20px;
    font-weight: 600;
    color: #333333;
    margin-bottom: 15px;
  }
  .bottom {
    font-size: 16px;
    font-weight: 500;
    color: #999999;
    line-height: 25px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    .total_p {
      margin-top: 20px;
      display: flex;
      align-items: center;
      p {
        font-weight: 800;
        color: #f96158;
        span {
          font-size: 28.69px;
        }
      }
    }
    .btn_wrap {
      width: 160px;
      height: 40px;
      background: #479dff;
      border-radius: 20px;
      line-height: 40px;
      font-size: 18px;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
      cursor: pointer;
    }
  }
}
</style>
